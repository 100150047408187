// ----------------------------------------------
// Utility for global uses

@mixin full-width-extend($bgColor) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    overflow: auto;
    background-color: $bgColor;
    position: relative;
    z-index: 0;
    @include clearfix();
    padding: 2em 0;
    &:before,
    &:after {
        background-color: $bgColor;
        content: '';
        width: 600px;
        position: absolute;
        z-index: 0;
        top: 0;
        bottom: 0;
    }
    &:before {
        left: -600px;
        right: 100%;
    }
    &:after {
        right: -600px;
        left: 100%;
    }
}